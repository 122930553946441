export const NuxtLogo = () => import('../../components/NuxtLogo.vue' /* webpackChunkName: "components/nuxt-logo" */).then(c => wrapFunctional(c.default || c))
export const MapboxglMeasureTool = () => import('../../components/mapboxgl-measure-tool.js' /* webpackChunkName: "components/mapboxgl-measure-tool" */).then(c => wrapFunctional(c.default || c))
export const BackTop = () => import('../../components/BackTop/index.vue' /* webpackChunkName: "components/back-top" */).then(c => wrapFunctional(c.default || c))
export const InterGrid = () => import('../../components/InterGrid/index.vue' /* webpackChunkName: "components/inter-grid" */).then(c => wrapFunctional(c.default || c))
export const IntegratingWheel = () => import('../../components/IntegratingWheel/index.vue' /* webpackChunkName: "components/integrating-wheel" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Footer/index.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const HomeRowItem = () => import('../../components/homeRowItem/index.vue' /* webpackChunkName: "components/home-row-item" */).then(c => wrapFunctional(c.default || c))
export const Chars = () => import('../../components/chars/index.vue' /* webpackChunkName: "components/chars" */).then(c => wrapFunctional(c.default || c))
export const Login = () => import('../../components/login/index.vue' /* webpackChunkName: "components/login" */).then(c => wrapFunctional(c.default || c))
export const MapboxPluginsDraw = () => import('../../components/mapbox-plugins/Draw.js' /* webpackChunkName: "components/mapbox-plugins-draw" */).then(c => wrapFunctional(c.default || c))
export const MapboxPluginsPlotManager = () => import('../../components/mapbox-plugins/PlotManager.js' /* webpackChunkName: "components/mapbox-plugins-plot-manager" */).then(c => wrapFunctional(c.default || c))
export const MapboxPluginsPlotWidget = () => import('../../components/mapbox-plugins/PlotWidget.js' /* webpackChunkName: "components/mapbox-plugins-plot-widget" */).then(c => wrapFunctional(c.default || c))
export const MapboxPluginsTheme = () => import('../../components/mapbox-plugins/theme.js' /* webpackChunkName: "components/mapbox-plugins-theme" */).then(c => wrapFunctional(c.default || c))
export const SvgIcon = () => import('../../components/svgIcon/index.vue' /* webpackChunkName: "components/svg-icon" */).then(c => wrapFunctional(c.default || c))
export const SplitPane = () => import('../../components/split-pane/index.vue' /* webpackChunkName: "components/split-pane" */).then(c => wrapFunctional(c.default || c))
export const SplitPaneResizer = () => import('../../components/split-pane/resizer.vue' /* webpackChunkName: "components/split-pane-resizer" */).then(c => wrapFunctional(c.default || c))
export const VipAlert = () => import('../../components/vip-alert/vip-alert.vue' /* webpackChunkName: "components/vip-alert" */).then(c => wrapFunctional(c.default || c))
export const VipContent = () => import('../../components/vip-alert/vip-content.vue' /* webpackChunkName: "components/vip-content" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
